import React, { useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import LandingStatistics from './LandingStatistics';
import PackagesCard from './atoms/PackagesCard';

import 'swiper/css';
import 'swiper/css/pagination';

export default function LandingPackages() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 640);
  };
  useEffect(() => {
    setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    // Render the mobile version
    return (
      <div id='packages'>
        <section className="py-5">
          <LandingStatistics value="PACKAGES" />

          <div className="relative">
            <div
              id="content"
              className="mx-auto px-6 py-8 carousel items-center scroll-smooth scrollbar-hide"
            >
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                style={{
                  '--swiper-pagination-bottom': '8px',
                  '--swiper-pagination-top': 'auto',
                }}
              >
                <SwiperSlide>
                  <PackagesCard
                    package1="BASIC"
                    numberSubmission="50"
                    submission="Free submissions"
                    numberEvent="1"
                    storage="100mb"
                    numberArchive="2"
                    templates=""
                    trackEvent=""
                    agenda=""
                    financialData=""
                    price="100"
                    disscount="20% discount for 5+ events"
                    disscount1="10% discount for 10+ events"
                    backgroundColor="linear-a1"
                    background="rectangle1"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <PackagesCard
                    package1="MEDIUM"
                    numberSubmission="200"
                    submission="Free submissions"
                    numberEvent="1"
                    storage="250mb"
                    numberArchive="3"
                    templates="Document templates"
                    trackEvent="Multi-track event"
                    agenda="Program agenda"
                    financialData=""
                    price="200"
                    disscount="20% discount for 5+ events"
                    disscount1="10% discount for 10+ events"
                    backgroundColor="linear-a2"
                    background="rectangle2"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <PackagesCard
                    package1="PRO"
                    numberSubmission="500"
                    submission="Free submissions"
                    numberEvent="1"
                    storage="500mb"
                    numberArchive="4"
                    templates="Document templates"
                    trackEvent="Multi-track event"
                    agenda="Program agenda"
                    financialData="Financial data analytics"
                    price="400"
                    disscount="20% discount for 5+ events"
                    disscount1="10% discount for 10+ events"
                    backgroundColor="linear-a3"
                    background="rectangle3"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <PackagesCard
                    package1="UNLIMITED"
                    numberSubmission="Unlimted"
                    submission="submissions"
                    numberEvent="1"
                    storage="1 GB"
                    numberArchive="5"
                    templates="Document templates"
                    trackEvent="Multi-track event"
                    agenda="Program agenda"
                    financialData="Financial data analytics"
                    price="500"
                    disscount="20% discount for 5+ events"
                    disscount1="10% discount for 10+ events"
                    backgroundColor="linear-a4"
                    background="rectangle4"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
      </div>
    );
  }
  return (
    <div id='packages'>
      <section className="mt-20 bg-wave-blue py-5 mb-20">
        <LandingStatistics value="PACKAGES" />

        <div className="mx-auto max-w-7xl px-6 lg:px-8 py-20">
          <div className="flex flex-wrap justify-center">
            <div className="m-[10px]">
              <PackagesCard
                package1="BASIC"
                numberSubmission="50"
                submission="Free submissions"
                numberEvent="1"
                storage="500 MB"
                numberArchive="2"
                templates=""
                trackEvent=""
                agenda=""
                financialData=""
                price="100"
                disscount="20% discount for 5+ events"
                disscount1="10% discount for 10+ events"
                backgroundColor="linear-a1"
                background="rectangle1"
              />
            </div>
            <div className="m-[10px]">
              <PackagesCard
                package1="MEDIUM"
                numberSubmission="200"
                submission="Free submissions"
                numberEvent="1"
                storage="1.5 GB"
                numberArchive="3"
                templates="Document templates"
                trackEvent="Multi-track event"
                agenda="Program agenda"
                financialData=""
                price="200"
                disscount="20% discount for 5+ events"
                disscount1="10% discount for 10+ events"
                backgroundColor="linear-a2"
                background="rectangle2"
              />
            </div>
            <div className="m-[10px]">
              <PackagesCard
                package1="PRO"
                numberSubmission="500"
                submission="Free submissions"
                numberEvent="1"
                storage="3 GB"
                numberArchive="4"
                templates="Document templates"
                trackEvent="Multi-track event"
                agenda="Program agenda"
                financialData="Financial data analytics"
                price="400"
                disscount="20% discount for 5+ events"
                disscount1="10% discount for 10+ events"
                backgroundColor="linear-a3"
                background="rectangle3"
              />
            </div>
            <div className="m-[10px]">
              <PackagesCard
                package1="UNLIMITED"
                numberSubmission="Unlimted"
                submission="submissions"
                numberEvent="1"
                storage="5 GB"
                numberArchive="5"
                templates="Document templates"
                trackEvent="Multi-track event"
                agenda="Program agenda"
                financialData="Financial data analytics"
                price="500"
                disscount="20% discount for 5+ events"
                disscount1="10% discount for 10+ events"
                backgroundColor="linear-a4"
                background="rectangle4"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
